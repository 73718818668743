<template>
    <el-upload
        class="upload-demo"
        :action="uploadAction"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :name="name"
        :headers="headers"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-exceed="handleExceed"
        :limit="1"
        :file-list="fileList"
        >
        <el-button
            size="small"
            type="primary"
        >点击上传</el-button>
    </el-upload>
</template>

<script>
// import flatry from '@admin/utils/flatry'
import Auth from '@admin/utils/auth'
export default {
  name: 'miniFileUploader',
  props: { file: {}, type: {} },
  data () {
    return {
      url: '',
      name: 'file',
      uploadAction: '',
      uploadData: null,
      uploadFolder: '',
      uploadSizeLimit: 1024000,
      headers: {
        'X-NAME': this.type,
        'X-Access-Token': Auth.getAccessToken()
      },
      fileList: []
    }
  },
  async mounted () {
    this.uploadAction = Auth.getHttpApiUrl() + 'setting/file'

    if (this.file) {
      let param = [
        this.file || ''
      ]
      this.fileList = param
    }
  },
  methods: {
    handleRemove (file, fileList) {
      this.$emit('on-remove-success', null)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response) {
      if (response.success === true) {
        this.$message.success(response.msg)
        this.$emit('on-upload-success', response.data)
      }
    },
    handleError (res) {
      let rg = /"msg":"(.*)","data"/ig
      console.log(res)
      const msg = rg.exec(res)
      this.$message.error(msg[1] || '上传失败')
    },
    handleExceed (res) {
      this.$message.error('当前存在文件，请删除后重试:(')
    }
  }
}
</script>
<style lang="scss">
.avatar-uploader {
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 16px;
        color: #8c939d;
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
    }

    .avatar {
        width: 90px;
        height: 90px;
        display: block;
        object-fit: cover;
    }
}
</style>
