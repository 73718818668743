<template>
  <div>
    <div class="setting-box">
      <el-form
        v-if="weChatPay"
        v-model="weChatPay"
        ref="form"
        label-width="100px"
      >
        <h3>微信支付设置</h3>
        <el-form-item
          prop='appid'
          label="appid"
        >
          <el-input
            type="text"
            v-model.trim="weChatPay.body.appid"
            placeholder="例:wx28e24e2c6cfc321n"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop='mchId'
          label="商户ID"
        >
          <el-input
            type="text"
            v-model.trim="weChatPay.body.mchId"
            placeholder="例:150000000"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop='secret'
          label="商户key"
        >
          <el-input
            type="text"
            v-model.trim="weChatPay.body.secret"
            show-password
            placeholder="例：bj4b21jk4n12kjkh421k4j12h421u8n"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop='secret'
          label="证书cert"
        >
          <file
            type="cert"
            :file="weChatPay.body.cert"
            @on-remove-success="certRemove"
            @on-upload-success="certSuccess"
          ></file>
        </el-form-item>

        <el-form-item
          prop='secret'
          label="证书key"
        >
          <file
            type="key"
            :file="weChatPay.body.key"
            @on-remove-success="keyRemove"
            @on-upload-success="keySuccess"
          ></file>
        </el-form-item>

        <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
            :loading="payLoading"
            @click="payFormSubmit"
          >保存</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="setting-box">
      <el-form
        v-if="aliPay"
        v-model="aliPay"
        ref="form"
        label-width="100px"
      >
        <h3>支付宝支付设置</h3>
        <el-form-item
          prop='appid'
          label="appid"
        >
          <el-input
            type="text"
            v-model.trim="aliPay.body.appid"
            placeholder="例：2016081501740000"
          ></el-input>
        </el-form-item>

        <el-form-item
          prop='mchId'
          label="支付宝公钥"
        >
          <el-input
            type="textarea"
            style="height:200px"
            v-model.trim="aliPay.body.publicKey"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop='secret'
          label="私钥"
        >
          <el-input
            type="textarea"
            style="height:200px"
            v-model.trim="aliPay.body.privateKey"
          ></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
            :loading="alipayLoading"
            @click="alipayFormSubmit"
          >保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'
import file from './components/FileUpload'

export default {
  name: 'MiniProgram',
  data () {
    return {
      payLoading: false,
      alipayLoading: false,
      aliPay: null,
      weChatPay: null
    }
  },
  components: { file },
  methods: {
    payFormSubmit (e) {
      this.editCreate(this.weChatPay)
    },
    alipayFormSubmit (e) {
      this.editCreate(this.aliPay)
    },
    async editCreate (param, success, callback) {
      const { data } = await flatry(SettingService.edit(param))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/setting/pay' })
      }
    },
    certSuccess (file) {
      this.weChatPay.body.cert = file
    },
    keySuccess (file) {
      this.weChatPay.body.key = file
    },
    certRemove () {
      this.weChatPay.body.cert = null
    },
    keyRemove () {
      this.weChatPay.body.key = null
    }
  },
  async mounted () {
    const { data } = await flatry(SettingService.edit())
    if (data.data.pay.weChatPay) {
      this.weChatPay = data.data.pay.weChatPay
    } else {
      this.weChatPay = {
        type: 3,
        body: {
          appid: '',
          secret: '',
          cert: '',
          key: ''
        }
      }
    }
    if (data.data.pay.aliPay) {
      this.aliPay = data.data.pay.aliPay
    } else {
      this.aliPay = {
        type: 4,
        body: {
          appid: '',
          publicKey: '',
          privateKey: ''
        }
      }
    }
  }
}
</script>
<style lang='scss'>
.setting-box {
    margin-bottom: 50px;
    padding: 24px;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    transition: 0.2s;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
        0 2px 4px 0 rgba(232, 237, 250, 0.5);
    h3 {
        margin-left: 80px;
        font-size: 1.06em;
    }
  textarea,.el-textarea__inner,.el-textarea{
    height:200px !important;
  }
  textarea{
    min-height: 200px !important;
  }
  .el-textarea__inner{
    height: 200px !important;
  }
}
</style>
